body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* overflow on mobile devices should be hidden as it prevents the
  default "bouncing/stretching" behavior when scrolling */
  overflow: hidden;

  background-color: #282c34;
}

/* bigger displays that are not touchscreen should be scrollable */
@media not screen and (max-device-width: 768px) and (pointer:coarse) {
  body {
    overflow: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainView {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  overflow: hidden;
}

.compose {
 padding: 7px 10px;
 display: flex;
 align-items: center;
 background: #282c34;
 border-top: 1px solid #47484A;
 position: fixed;
 width: 100vw;
 bottom: 0px;
 color: white;
}

.send {
  background: transparent;
  border: none;
  color: inherit;
  border-radius: 50%;
  outline: none;
  transition: 0.7s;
  margin-right: 2vw;
}

.send:hover{
  background: #1C1D20;
}

.center {
  margin-left: 15%;
}

#input {
  flex: 4;
  border: none;
  font-size: 14px;
  height: 30px;
  background: none;
  overflow: auto;
  color: white;
  padding-left: 1em;
  padding-right: 1em;
  outline: none;
}

#input::placeholder {
  opacity: 0.7;
}

/* ============================================================================= */
#messagesView {
  overflow: auto;
  height: calc(100vh - 47px);
}

/* this accounts for mobile devices's menu bar
  tested on iOS safari
*/
@media only screen and (max-device-width: 768px) {
  #messagesView {
    margin-top: 120px;
    height: calc(100vh - 47px - 125px);
  }
}

.messageContiner {
  width: 100%;
  /* display: inline-block;
  position: relative; */
  /* float: left; removes spaces between messages ( TODO look at error message ) */
  display: flex;
}

.message {
  background: black;
  color: white;
  border-radius: 20px;
  padding: 10px 15px;
  font-size: 14px;
  width: fit-content;
  max-width: 70%;
  margin: 1px 15px;
  overflow-wrap: break-word;
  /* float:left; */
}

.mine {
  background: #007aff;
  color: inherit;
  /* float: right; */
  margin-left: auto;
}
.mine::selection {
  background: #171765;
}

.fixedMargin {
  margin-left: 15px;
}

/* ================================ time when the message was sent ===================================== */
.beforeMessage, .afterMessage {
  /* display: none; */
  /* display: inline;
  margin: auto;
  text-align: center;
  vertical-align: middle; */
  /* position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
  /* position: relative;
  top: 50%;
  transform: translateX(-50%); */
  /* margin:auto 0; */
  margin: auto 0;
  font-size: 0.7em;
}
.beforeMessage {
  margin-left: auto;
}

/* ================================================================================ */
.indexPage {
  padding: 0;
  margin: 0;
  position: absolute;
  width: 100%;
}

/* TODO code duplication */

.box {
  margin: 0 auto;
  margin-top: 2em;
  width: 20%;
  background: #16181d;
  height: 20%;
  min-height: 150px;
  min-width: 200px;
  max-height: 170px;
  max-width: 400px;
}

.boxSecondClient {
  margin: 0 auto;
  margin-top: 2em;
  width: 20%;
  background: #16181d;
  min-height: 80px;
  min-width: 200px;
  padding: 1em 2em;
  max-width: 400px;
  word-wrap: break-word;
}

.secretDescription {
  font-size: 0.8em;
}

/*.indexParagraph {
  text-align: center;
  padding: 0;
  margin: 0;
}*/

.readyBtn {
  text-align: center;
  padding-top: 1em;
}

#readyLink {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  background: #282c34;
  /*padding: 10px;
  border: 4px solid #282c34;*/
  padding: 14px;
  display: inline-block;
  transition: all 0.4s ease 0s;
}

#readyLink:hover {
  color: #282c34;
  background: #f6b93b;
  border-color: #f6b93b;
  transition: all 0.4s ease 0s;
}

/*TODO remove */
/* if readyLink is a div*/
div#readyLink {
  margin-top: 1em;
  margin-bottom: 1em;
  cursor: pointer;
}


/* ============== input-a-secret field =======================================*/
.secretField {
   font-family: inherit;
   width: 90%;
   border: 0;
   border-bottom: 2px solid #9b9b9b;
   outline: 0;
   font-size: 1.3rem;
   color: #fff;
   padding: 14px 0;
   margin-left: 10px;
   background: transparent;
   transition: border-color 0.2s;
}
 .secretField::placeholder {
   color: transparent;
}
 .secretField:placeholder-shown ~ .secretLabel {
   font-size: 1.3rem;
   cursor: text;
   top: 40px;
}
 .secretLabel {
   position: absolute;
   top: 0;
   display: block;
   transition: 0.2s;
   font-size: 1rem;
   margin-left: 10px;
   color: #9b9b9b;
}
 .secretField:focus {
   padding-bottom: 13px;
   font-weight: 700;
   border-width: 3px;
   border-image: linear-gradient(to right, #11998e, #38ef7d);
   border-image-slice: 1;
}
 .secretField:focus ~ .secretLabel {
   position: absolute;
   top: 0;
   display: block;
   transition: 0.2s;
   font-size: 1rem;
   color: #11998e;
   font-weight: 700;
}
/* reset input */
 .secretField:required, .secretField:invalid {
   box-shadow: none;
}

/* ================================================================= */

/* =========================== loader ===============================*/
.loader {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 0;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.loader div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* ====================== invalid token page ================================ */
.invalidTokenMessage {
  margin: 0 auto;
  margin-top: 4em;
  width: 50%;
  /*background: #16181d;*/
  /*height: 20%;*/
  min-height: 150px;
  min-width: 200px;
  text-indent: 3em;
}

.goBackMsg {
  margin-top: 4em;
}

.goBackLink {
  color: #aba0ba;
}

/* ========================== scroll bar =================================== */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #282C34;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3A3E45;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5A5A5C;
}

/* Handle on click */
::-webkit-scrollbar-thumb:active {
  background: #1E1F23;
}

.invisible {
  color: transparent;
  /* display: none; */ /* ??? */
}
